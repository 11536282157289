import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import renderModule, { ModuleProps } from '@components/module';
import SEO from '@components/seo';
import Layout from '@layouts/default-layout';
import generateId from '@helpers/generate-id';
import {
  UnknownRecord,
  useMergePrismicPreviewData,
  UsePrismicPreviewDataResult,
  withPrismicPreview,
} from 'gatsby-plugin-prismic-previews';
import { Container } from './index';

const blogPageIdGenerator = generateId();

interface BlogProps extends UnknownRecord {
  prismicGeneralConfig: {
    data: {
      favicon?: {
        url?: string;
      };
    };
  };
  prismicBlog: {
    data: {
      page_title: {
        text: string;
      };
      body: ModuleProps[];
    };
  };
}

const query = graphql`
  query BlogQuery {
    prismicGeneralConfig {
      ...GeneralConfigFragment
    }
    prismicBlog {
      ...BlogFragment
    }
  }
`;

const BlogPage: React.FC = (): JSX.Element | null => {
  const staticQueryResult: BlogProps = useStaticQuery(query);

  const {
    data: {
      prismicBlog,
      prismicGeneralConfig: {
        data: { favicon },
      },
    },
  }: UsePrismicPreviewDataResult<BlogProps> =
    useMergePrismicPreviewData<BlogProps>(staticQueryResult);

  const { page_title } = prismicBlog.data;

  return (
    <Layout>
      <SEO title={page_title.text} faviconHref={favicon?.url || ''} />
      <Container>
        {prismicBlog.data.body.map(
          (module) =>
            module && (
              <React.Fragment key={blogPageIdGenerator.next().value}>
                {renderModule({ ...module })}
              </React.Fragment>
            )
        )}
      </Container>
    </Layout>
  );
};

export default withPrismicPreview(BlogPage);
